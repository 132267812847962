//./components/Balance-Card.js
import React, {useState, useEffect, useRef }from "react";
import {
  Card,
  CardHeader,
  CardBody,
  CardText
} from "reactstrap";


const BalanceCard = ({ balance }) => {
    
  
  useEffect(() => {
    
  });

  return (

    <Card className="my-2 p-0" inverse style={{ margin: 5, backgroundColor: "#00000055", borderRadius: 25 }}>
        <CardBody style={{padding: "0"}}>
            <CardText style={{fontSize: "1em", fontWeight: "bold", display: 'flex', padding: 5, paddingLeft: 15, paddingRight: 15}}>
                {balance} NSTE
            </CardText>
        </CardBody>
    </Card>

  );
};

export default BalanceCard;