// pages/monsters.js
import { useEffect, useState } from 'react';
import Navbar from './components/Navbar';
import axios from 'axios';
import { TabContent, TabPane, Nav, NavItem, NavLink, Card, CardText, Row, Col, Container, CardHeader, CardBody, CardFooter } from 'reactstrap';
import classnames from 'classnames';
import withAuth from '../hoc/withAuth'; // Importe o HOC
import StatusAlert from './components/Status-Alert'; // Importe o componente StatusAlert
import MonsterCard from './components/Monster-Card';
import MyToast from "./components/My-Toast";

function Monsters(props) {
  const [activeTab, setActiveTab] = useState('1');
  const [inputValue, setInputValue] = useState('');
  const [token] = useState(localStorage.getItem('token'));
  const [showToast, setShowToast] = useState(false);
  const [toastTitle, setToastTitle] = useState("");
  const [toastText, setToastText] = useState("");
  const [bgClass, setBgClass] = useState("");
  const [hashrate, setHashrate] = useState(0);
  const [hashrates, setHashrates] = useState(0);
  const [botaoDesabilitado, setBotaoDesabilitado] = useState(false);
  const [custoTotal, setCustoTotal] = useState(0);
  const custo = 295;
  const [custoColor, setCustoColor] = useState('#ffffff');

  const toggle = tab => {
    if (activeTab !== tab) setActiveTab(tab);
  }
  const [monsters, setMonsters] = useState([]);
  const [feito, setFeito] = useState(false);
  const [balances, setBalances] = useState(0);

  const { userStatus, isAdmin } = props;

  const fetchBalances = async () => {
    try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_ADDRESS}getBalances.php?token=`+token
        );
        const data = response.data.data;
        setBalances(data);
        console.log(data);
    } catch (error) {
    console.error("Erro ao buscar monsters:", error);
    }
  };

  const fetchMonsters = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_ADDRESS}getMonsters.php?token=`+token
      );
      const data = response.data.data;
      setMonsters(data);
      setHashrate(response.data.hash_sum);
      setHashrates(response.data.sistem_hash);

      
    } catch (error) {
      console.error("Erro ao buscar monsters:", error);
    }
  };

  const quantidadeChange = async (e) => {
    setInputValue(e);
    setCustoTotal(e * custo);
    if(balances['NSTE'].amount >= e * custo){
        setCustoColor('#ffffff');
    }else{
        setCustoColor('#eb4034');
    }
  };

  // Define a função para esconder o Toast automaticamente
  const hideToast = () => setShowToast(false);

  const handleSubmit = async (event) => {
    event.preventDefault();
    if(inputValue * custo < balances['NSTE'].amount){
        
        try {

            const response = await axios.put(
                `${process.env.REACT_APP_API_ADDRESS}openBox.php`,
                { boxes: inputValue,
                token: token }
                
            );

            fetchMonsters();

            // Limpar o campo de input após o envio bem-sucedido
            setInputValue('');

            // Mostrar toast de sucesso
            setShowToast(true);
            setTimeout(hideToast, 3000); // Fechar o toast após 3 segundos
            setToastTitle('Sucesso');
            setToastText(response.data.message);
            setBgClass('bg-success');
            fetchBalances();
            
        } catch (error) {
            // Mostrar toast de erro
            setShowToast(true);
            setTimeout(hideToast, 3000); // Fechar o toast após 3 segundos
            setToastTitle('Erro');
            setToastText(error.response.data.message);
            setBgClass('bg-danger');
        }
    }else{
        // Mostrar toast de erro
        setShowToast(true);
        setTimeout(hideToast, 3000); // Fechar o toast após 3 segundos
        setToastTitle('Erro');
        setToastText("NSTE Insuficiente!!");
        setBgClass('bg-danger');
    }
    
  };



  useEffect(() => {
    if (!feito) {
      fetchMonsters(); // Chame a função fetchMonsters no useEffect
      setFeito(true);
    }
  }, [feito]);

  useEffect(() => {
    const fetchData = async () => {
      await fetchBalances(); // Chame a função fetchBalances aqui
    };

    fetchData(); // Chame a função assim que o componente for montado

    const interval = setInterval(async () => {
      await fetchData(); // Chame a função fetchMonsters a cada 1 minuto
    }, 60000); // 60000 milissegundos = 1 minuto

    // Limpe o intervalo quando o componente for desmontado para evitar vazamentos de memória
    return () => clearInterval(interval);
  }, []);

  return (
    <div style={{ backgroundColor: '#dddddd', paddingBottom: 20 }}>
      <Navbar isAdmin={isAdmin} balances={balances}/>
      <MyToast
        isOpen={showToast}
        title={toastTitle}
        message={toastText}
        bgClass={bgClass}
      />
      <Container className="mt-4">
        <StatusAlert userStatus={userStatus} />
        
        <Row className="h-100 text-center justify-content-center" fluid="true">
        <Card className="my-2 p-0" inverse style={{ width: '25rem', backgroundColor: "#13181F", margin: 5 }}>
            <CardHeader style={{fontSize: "1.5em", fontWeight: "bold"}}>Hash Total do Sistema</CardHeader>
            <CardText style={{fontSize: "1.5em", fontWeight: "bold", backgroundColor: "#00000055", borderRadius: "25px", display: 'flex', margin: 15}}>
                <Col style={{ flex: 1 }}>
                ⛏️ 
                </Col>
                <Col style={{ flex: 1, color: '#cf8c30' }}>
                {hashrates}
                </Col>
            </CardText>
        </Card>
        <Card className="my-2 p-0" inverse style={{ width: '25rem', backgroundColor: "#13181F", margin: 5}}>
            <CardHeader style={{fontSize: "1.5em", fontWeight: "bold"}}>Meu Hash</CardHeader>
            <CardText style={{fontSize: "1.5em", fontWeight: "bold", backgroundColor: "#00000055", borderRadius: "25px", display: 'flex', margin: 15}}>
                <Col style={{ flex: 1 }}>
                ⛏️ 
                </Col>
                <Col style={{ flex: 1 }}>
                {hashrate}
                </Col>
            </CardText>
        </Card>
        </Row>
        
        <form onSubmit={handleSubmit}>
        <Row className="h-100 text-center justify-content-center" fluid="true">
            
                <Card className="my-2 p-0" inverse style={{ width: '30rem', backgroundColor: "#13181F", paddingBottom: 20 }}>
                    <CardHeader>Solution Monsters 1 BOX = 295 NSTE</CardHeader>
                    <CardText style={{margin: 20}}>
                        <p>Quantidade</p>
                        <input
                        type="number"
                        min="1"
                        value={inputValue}
                        onChange={(e) => quantidadeChange(e.target.value)}
                        required
                        />
                    </CardText>
                    <CardText style={{marginBottom: 20}}>
                        <button type="submit" disabled={botaoDesabilitado}>Comprar</button>
                    </CardText>
                    <CardText style={{marginBottom: 20, color: custoColor}}>
                        Total: {custoTotal} NSTE
                    </CardText>
                    <CardFooter style={{display: 'flex', backgroundColor: "#2c2e30"}}>
                        <Col style={{ flex: 1, display: 'flex', alignItems: 'center' }}>
                            <Col style={{ display: 'flex', alignItems: 'center' }}>
                                <div style={{
                                backgroundColor: "#6C757D",
                                borderRadius: 25,
                                border: "2px solid #999999",
                                width: "1em",
                                height: "1em",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center"
                                }}>
                                {/* Seu conteúdo */}
                                </div>
                            </Col>
                            <Col style={{ display: 'flex', alignItems: 'center' }}>
                                Comum 50%
                            </Col>
                        </Col>
                        <Col style={{ flex: 1, display: 'flex', alignItems: 'center' }}>
                            <Col style={{ display: 'flex', alignItems: 'center' }}>
                                <div style={{
                                backgroundColor: "#2F3F18",
                                borderRadius: 25,
                                border: "2px solid #999999",
                                width: "1em",
                                height: "1em",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center"
                                }}>
                                {/* Seu conteúdo */}
                                </div>
                            </Col>
                            <Col style={{ display: 'flex', alignItems: 'center' }}>
                                Incomum 35%
                            </Col>
                        </Col>
                        <Col className="my-2 p-0" style={{ flex: 1, display: 'flex', alignItems: 'center' }}>
                            <Col style={{ display: 'flex', alignItems: 'center' }}>
                                <div style={{
                                backgroundColor: "#1E2F5C",
                                borderRadius: 25,
                                border: "2px solid #999999",
                                width: "1em",
                                height: "1em",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center"
                                }}>
                                {/* Seu conteúdo */}
                                </div>
                            </Col>
                            <Col style={{ display: 'flex', alignItems: 'center' }}>
                            Raro 12%
                            </Col>
                        </Col>
                        <Col style={{ flex: 1, display: 'flex', alignItems: 'center' }}>
                            <Col style={{ display: 'flex', alignItems: 'center' }}>
                                <div style={{
                                backgroundColor: "#3E1F58",
                                borderRadius: 25,
                                border: "2px solid #999999",
                                width: "1em",
                                height: "1em",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center"
                                }}>
                                {/* Seu conteúdo */}
                                </div>
                            </Col>
                            <Col style={{ display: 'flex', alignItems: 'center' }}>
                            Lendário 2.5%
                            </Col>
                        </Col>
                        <Col style={{ flex: 1, display: 'flex' }}>
                            <Col style={{ display: 'flex', alignItems: 'center' }}>
                                <div style={{
                                backgroundColor: "#5F4E12",
                                borderRadius: 25,
                                border: "2px solid #999999",
                                width: "1em",
                                height: "1em",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "flex-start"
                                }}>
                                {/* Conteúdo do primeiro div */}
                                </div>
                            </Col>
                            <Col style={{ display: 'flex', alignItems: 'center', width: '85%'}}>
                                Épico 0.5%
                            </Col>
                        </Col>
                    </CardFooter>
                </Card> 
            
        </Row></form> 
        <Row className="h-100 text-center justify-content-center" fluid="true">
        {monsters && monsters.length > 0
                ? [...monsters]
                  .map((monster) => <MonsterCard key={monster.id} monster={monster} />)
                : ""}
        </Row>


      </Container>
    </div>

  );
}


export default withAuth(Monsters); // Use o HOC
