// pages/dashboard.js
import { useEffect, useState } from 'react';
import Navbar from './components/Navbar';
import axios from 'axios';
import { TabContent, TabPane, Nav, NavItem, NavLink, Card, CardText, Row, Container, CardHeader, CardBody, Col } from 'reactstrap';
import withAuth from '../hoc/withAuth'; // Importe o HOC
import StatusAlert from './components/Status-Alert'; // Importe o componente StatusAlert
import FarmCard from './components/Farm-Card';

function Dashboard(props) {
  const [inputValue, setInputValue] = useState('');
  const [token] = useState(localStorage.getItem('token'));
  const [showToast, setShowToast] = useState(false);
  const [toastTitle, setToastTitle] = useState("");
  const [toastText, setToastText] = useState("");
  const [bgClass, setBgClass] = useState("");
  const [hashrate, setHashrate] = useState(0);
  const [hashrates, setHashrates] = useState(0);
  const [farms, setFarms] = useState([]);
  const custo = 295;

  const [feito, setFeito] = useState(false);
  const [balances, setBalances] = useState(0);

  const { userStatus, isAdmin } = props;

  const fetchBalances = async () => {
    try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_ADDRESS}getBalances.php?token=`+token
        );
        const data = response.data.data;
        setBalances(data);
    } catch (error) {
    console.error("Erro ao buscar balances:", error);
    }
  };
  const fetchFarms = async () => {
    try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_ADDRESS}getFarms.php?token=`+token
        );
        const data = response.data.data;
        setFarms(data);
        console.log(data);
    } catch (error) {
    console.error("Erro ao buscar farms:", error);
    }
  };

  const fetchMonsters = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_ADDRESS}getMonsters.php?token=`+token
      );
      setHashrate(response.data.hash_sum);
      setHashrates(response.data.sistem_hash);

      
    } catch (error) {
      console.error("Erro ao buscar monsters:", error);
    }
  };

  // Define a função para esconder o Toast automaticamente
  const hideToast = () => setShowToast(false);

  useEffect(() => {
    if (!feito) {
      fetchMonsters(); // Chame a função fetchMonsters no useEffect
      setFeito(true);
    }
  }, [feito]);

  useEffect(() => {
    const fetchData = async () => {
      await fetchBalances(); // Chame a função fetchBalances aqui
    };

    fetchData(); // Chame a função assim que o componente for montado

    const interval = setInterval(async () => {
      await fetchData(); // Chame a função fetchMonsters a cada 1 minuto
    }, 60000); // 60000 milissegundos = 1 minuto

    // Limpe o intervalo quando o componente for desmontado para evitar vazamentos de memória
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      await fetchFarms(); // Chame a função fetchFarms aqui
    };

    fetchData(); // Chame a função assim que o componente for montado

    const interval = setInterval(async () => {
      await fetchData(); // Chame a função fetchMonsters a cada 1 minuto
    }, 60000); // 60000 milissegundos = 1 minuto

    // Limpe o intervalo quando o componente for desmontado para evitar vazamentos de memória
    return () => clearInterval(interval);
  }, []);

  return (
    <div style={{ backgroundColor: '#dddddd', paddingBottom: 20 }}>
      <Navbar isAdmin={isAdmin} balances={balances}/>
      <Container className="mt-4">
        <StatusAlert userStatus={userStatus} />
        <Row className="h-100 text-center justify-content-center" fluid="true">
        {farms && farms.length > 0
                ? [...farms]
                  .map((farm) => <FarmCard key={farm.id} farm={farm} totalHash={hashrates} myHash={hashrate} fetchFarms={fetchFarms}/>)
                : ""}
        </Row>
      </Container>
    </div>

  );
}


export default withAuth(Dashboard); // Use o HOC
