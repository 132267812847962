//./components/Farm-Card.js
import React, {useState, useEffect, useRef }from "react";
import {
  Card,
  CardHeader,
  CardBody,
  CardText,
  ListGroup,
  ListGroupItem,
  ListGroupItemHeading,
  CardFooter,
  Col,
  Row,
  Button
} from "reactstrap";
import { format } from "date-fns";
import axios from "axios";


const FarmCard = ({ farm, totalHash, myHash, fetchFarms }) => {

    const coletarRecompensa = () => {
        if(farm.uncolected > 0){
            axios.put(`${process.env.REACT_APP_API_ADDRESS}colectFarm.php`, {
            token: localStorage.getItem('token'), // substitua pelo token correto
            farmid: farm.id, // supondo que farm.id seja o id da fazenda
            })
            .then(response => {
            // Lógica após a requisição ser bem-sucedida
            console.log("Recompensa coletada com sucesso!", response.data);
            fetchFarms();
            // Adicione aqui a lógica para atualizar os dados na interface, se necessário
            })
            .catch(error => {
            // Lógica para lidar com erros na requisição
            console.error("Erro ao coletar recompensa:", error);
            });
        }else{
            console.log("Sem recompensa!");
        }
      };
      const hashPower = () => {
        if (farm.ticker === "BTC") {
            return `${(((farm.distribution / totalHash) * 100) * 100000000).toFixed(0)} satoshi`;
        } else if ( farm.ticker === "LTC")
        {
            return `${(((farm.distribution / totalHash) * 100) * 100000000).toFixed(0)} litoshi`;
        } else {
            return `${((farm.distribution / totalHash) * 100).toFixed(2)} ${farm.ticker}`;
        }
      };

      const reward = () => {
        if (farm.ticker === "BTC") {
            return `${farm.uncolected.toFixed(8)} ${farm.ticker}`;
        } else if ( farm.ticker === "LTC")
        {
            return `${farm.uncolected.toFixed(8)} ${farm.ticker}`;
        } else {
            return `${farm.uncolected.toFixed(3)} ${farm.ticker}`;
        }
      };
  
  useEffect(() => {
    
  });

  return (

    <Card className="my-2 p-0" inverse style={{ width: '18rem', margin: 5, backgroundColor: '#111111' }}>
        <CardHeader style={{fontWeight: "bold"}}>Farm - {farm.coin_nome}</CardHeader>
        <CardBody style={{padding: 20, backgroundColor: '#151518'}}>
            <CardText style={{fontSize: "0.8em", fontWeight: "bold", display: 'flex', margin: 0, color: '#888888'}}>
                Distribuição Diária
            </CardText>
            <CardText style={{fontSize: "1em", fontWeight: "bold", display: 'flex'}}>
            {farm.distribution} {farm.ticker}
            </CardText>
            <CardText style={{fontSize: "0.8em", fontWeight: "bold", display: 'flex', margin: 0, color: '#888888'}}>
            Total Hash Power
            </CardText>
            <CardText style={{fontSize: "1em", fontWeight: "bold", display: 'flex', border: '2px solid #8888ff', borderRadius: 10, padding: 5, backgroundColor: '#111144', color: '#9999dd'}}>
            ⛏️ {totalHash}
            </CardText>
            <CardText style={{fontSize: "0.8em", fontWeight: "bold", display: 'flex', margin: 0, color: '#888888'}}>
            My Hash Power
            </CardText>
            <CardText style={{fontSize: "1em", fontWeight: "bold", display: 'flex', border: '2px solid #8888ff', borderRadius: 10, padding: 5, backgroundColor: '#111144', color: '#9999dd'}}>
            ⛏️ {myHash}
            </CardText>
            <CardText style={{fontSize: "0.8em", fontWeight: "bold", display: 'flex', margin: 0, color: '#888888'}}>
            Mining Hate
            </CardText>
            <Row style={{paddingTop: 5, paddingBottom: 5, fontWeight: "bold", borderRadius: 10, marginLeft: 0, marginRight: 0, backgroundColor: '#333333'}}>
                <CardText style={{fontSize: "0.75em", fontWeight: "bold", display: 'flex', margin: 0, color: '#888888'}}>
                100 Hash Power = {hashPower()} / DIA
                </CardText>
                <CardText style={{fontSize: "1em", fontWeight: "bold", display: 'flex'}}>
                {reward()}
                </CardText>
            </Row>
            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
            <Button
                color="primary"
                style={{
                    fontSize: "0.9em",
                    backgroundColor: '#2678fc',
                    color: 'white',
                    transition: 'background-color 0.3s',
                    borderRadius: '10px',
                    width: '10em',
                    textAlign: 'center',
                    cursor: 'pointer',
                    border: 'none',
                    outline: 'none'
                }}
                onClick={coletarRecompensa}
                onMouseOver={(e) => {
                    e.target.style.backgroundColor = '#1e60c9';
                }}
                onMouseOut={(e) => {
                    e.target.style.backgroundColor = '#2678fc';
                }}
            >
                Coletar
            </Button>
        </div>
        </CardBody>
    </Card>

  );
};

export default FarmCard;